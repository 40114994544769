import React from 'react'
// import ReactMarkdown from 'react-markdown'
// import fetch from 'isomorphic-unfetch'

// Components
import { Seo, Footer } from '../../components'

// Styled Components
import { PageWrapper, ContentWrapper } from '../../styles/layout'
// import { Helmet } from 'react-helmet'

const NotFound = () => (
  <PageWrapper>

    <Seo title="404 - Page Not Found | SVG Artista" slug="404" />
    {/* Main Content */}
    <ContentWrapper
      className="page-content page-404"
    >
      <h1 className="title-404">404</h1>
      <h1>Page Not Found</h1>
      <h3>Oops… Whatever you were looking for isn&apos;t here :( </h3>
    </ContentWrapper>

    {/* Footer */}
    <Footer />

  </PageWrapper>
)

export default NotFound
